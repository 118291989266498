import React, { useMemo } from 'react';
import { NbaStatsBody, Sport } from '../../types';
import { Skeleton, Flex } from 'antd';
import { useGetNflPlayerStatsQuery } from '../../redux/queries/nflApi';
import { useGetMlbPlayerStatsQuery } from '../../redux/queries/mlbApi';
import { useGetNbaPlayerStatsQuery } from '../../redux/queries/nbaApi';
import { useGetNhlPlayerStatsQuery } from '../../redux/queries/nhlApi';
import MarketTableStats from './MarketTableStats';
import { Rating } from './PriceRating';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import BookmakerDisplay from './BookmakerDisplay';
import { priceAdjustedRating } from '../../utils/calculatePropRating';

function PlayerStats({
  body,
  bookmakers,
  isHome,
  homeTeam = null,
  eventDateTime = null,
}: {
  body: NbaStatsBody;
  bookmakers: any;
  isHome: boolean;
  homeTeam?: string | null;
  eventDateTime?: string | null;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const { data: nbaPlayerStats, isFetching: nbaPlayerStatsLoading } = useGetNbaPlayerStatsQuery(
    {
      playerId: body.playerId as string,
      body: {
        ...body,
        rating: {
          isHome,
          homeTeam,
          eventDateTime,
        },
      },
    },
    { skip: body.sport !== Sport.nba }
  );

  const { data: nflPlayerStats, isFetching: nflPlayerStatsLoading } = useGetNflPlayerStatsQuery(
    {
      playerId: body.playerId as string,
      body: {
        ...body,
        rating: {
          isHome,
          homeTeam,
          eventDateTime,
        },
      },
    },
    { skip: body.sport !== Sport.nfl }
  );

  const { data: mlbPlayerStats, isFetching: mlbPlayerStatsLoading } = useGetMlbPlayerStatsQuery(
    {
      playerId: body.playerId as string,
      body: {
        ...body,
        rating: {
          isHome,
          homeTeam,
          eventDateTime,
        },
      },
    },
    { skip: body.sport !== Sport.mlb }
  );

  const { data: nhlPlayerStats, isFetching: nhlPlayerStatsLoading } = useGetNhlPlayerStatsQuery(
    {
      playerId: body.playerId as string,
      body: {
        ...body,
        rating: {
          isHome,
          homeTeam,
          eventDateTime,
        },
      },
    },
    { skip: body.sport !== Sport.nhl }
  );

  const stats = useMemo(() => {
    return body.sport === Sport.nfl && nflPlayerStats
      ? nflPlayerStats
      : body.sport === Sport.mlb && mlbPlayerStats
        ? mlbPlayerStats
        : body.sport === Sport.nba && nbaPlayerStats
          ? nbaPlayerStats
          : body.sport === Sport.nhl && nhlPlayerStats
            ? nhlPlayerStats
            : nhlPlayerStats;
  }, [body.sport, nflPlayerStats, mlbPlayerStats, nbaPlayerStats, nhlPlayerStats]);

  if (nflPlayerStatsLoading || mlbPlayerStatsLoading || nbaPlayerStatsLoading || nhlPlayerStatsLoading) {
    return <Skeleton />;
  }

  return (
    <Flex
      vertical={isTabletOrMobile}
      gap={isTabletOrMobile ? 0 : 32}
      align="center"
      style={{
        borderBottom: '1px solid #333',
        paddingTop: 8,
        background: 'linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(45,45,45,1) 100%)',
      }}
    >
      <MarketTableStats
        body={body}
        stats={stats?.stats}
        streak={stats?.streak}
        streakDifferential={stats?.streakDifferential}
      />
      <Flex gap={8} align="center" className="markets-table__bookmakers" style={{ flexGrow: 1, maxWidth: '100%' }}>
        {bookmakers.map((bookmaker: any) => {
          return (
            <BookmakerDisplay
              key={`${body.playerId}-${bookmaker.name}`}
              bookmaker={bookmaker.name}
              price={bookmaker.price}
            >
              <Rating rating={priceAdjustedRating({ rating: stats?.rating as number, price: bookmaker.price })} />
            </BookmakerDisplay>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default PlayerStats;
