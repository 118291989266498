import React, { useState, useMemo } from 'react';
import orderby from 'lodash.orderby';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { setBookmakers, setExpandPicks } from '../../../redux/slices/bettingPicksSlice';
import {
  Sport,
  NbaStatsBody,
  MlbTeam,
  NbaTeam,
  NflTeam,
  NhlTeam,
  MlbPlayerInfo,
  NbaPlayerInfo,
  NflPlayerInfo,
  NhlPlayerInfo,
  MlbPlayerStatResult,
  NbaPlayerStatResult,
  NflPlayerStatResult,
  NhlPlayerStatResult,
} from '../../../types';
import {
  useGetOurPicksQuery,
  useLazyGetOurPicksQuery,
  useGetPicksBookmakersQuery,
} from '../../../redux/queries/bettingApi';
import Pick from './Pick';
import './OurPicks.scss';
import { Button, Flex, Spin, Select, Tag } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../../constants';
import { socket } from '../../../socket/socket';
import { DownOutlined, UpOutlined, ReloadOutlined } from '@ant-design/icons';
import { BOOKMAKER_LOGOS, BOOKMAKER_NAMES } from '../../../constants';

export type GroupedPick = PicksRecord & {
  picks: PicksRecord[];
};

export type GroupedPicksRecord = Record<string, GroupedPick>;

export type PicksRecord = {
  id: string;
  sport: Sport;
  eventId: string;
  commence_time: string;
  homeTeam: undefined | MlbTeam | NbaTeam | NflTeam | NhlTeam; // ATL
  awayTeam: undefined | MlbTeam | NbaTeam | NflTeam | NhlTeam; // CIN
  playerId: undefined | string;
  requestBody: NbaStatsBody;
  bookmaker: string;
  rating: number;
  price: number;
  player?: undefined | MlbPlayerInfo | NbaPlayerInfo | NflPlayerInfo | NhlPlayerInfo;
  stats?: null | MlbPlayerStatResult | NbaPlayerStatResult | NflPlayerStatResult | NhlPlayerStatResult;
  marketName: string;
};

export function groupPicksFormatter(picks: PicksRecord[]): GroupedPick[] {
  const groupPicks = (picks || []).reduce((obj: GroupedPicksRecord, pick: PicksRecord) => {
    const { playerId, eventId, marketName, requestBody } = pick;

    const key = `${playerId}-${eventId}-${marketName}-${requestBody.threshold}-${requestBody.overUnder}`;
    if (obj[key]) {
      obj[key].picks.push(pick);
    } else {
      obj[key] = {
        ...pick,
        picks: [pick],
      };
    }
    return obj;
  }, {});

  return Object.values(groupPicks);
}

function OurPicks({ sportId, condensed }: { sportId: Sport; condensed: boolean }) {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [noOfPicks, setNoOfPicks] = useState(20);

  const bookmakers = useSelector((state: RootState) => state.bettingPicks.bookmakers);
  const expandPicks = useSelector((state: RootState) => state.bettingPicks.expandPicks);

  socket.on('new-picks-are-in', (body: { sportId: Sport }) => {
    if (body.sportId === sportId) {
      setShowRefreshButton(true);
    }
  });

  const { data: pickBookmakers = [] } = useGetPicksBookmakersQuery({ sport: sportId }, { skip: !sportId });

  const [getPicks, { isLoading: isLoadingPicks, isFetching: isFetchingPicks }] = useLazyGetOurPicksQuery();
  const { data: picks, isLoading: picksLoading } = useGetOurPicksQuery(
    { sport: sportId, bookmakers, noOfPicks },
    { skip: !sportId }
  );

  const groupPicks = useMemo(() => {
    return groupPicksFormatter(picks || []);
  }, [picks]);

  const topPicks: GroupedPick[] = groupPicks.filter((groupedPick: GroupedPick) => {
    return groupedPick.picks.some((pick: PicksRecord) => pick.rating >= 80);
  });

  const filteredPicks: GroupedPick[] = groupPicks.filter(groupedPick => {
    return groupedPick.picks.some((pick: PicksRecord) => pick.rating < 80);
  });

  if (picksLoading)
    return (
      <Flex justify="center" style={{ height: 300 }}>
        <Spin size="large" />
      </Flex>
    );

  const topPicksCss = cn('our-picks__top-picks', {
    'our-picks__top-picks--mobile': isTabletOrMobile,
  });

  const otherPicksCss = cn('our-picks__other-picks', {
    'our-picks__other-picks--mobile': isTabletOrMobile,
  });

  const ourPicksWrapper = cn('our-picks__wrapper', {
    'our-picks__wrapper--mobile': isTabletOrMobile,
  });

  const handleChange = (value: string[]) => {
    dispatch(setBookmakers({ bookmakers: value }));
  };

  const handleTagChange = (value: string, checked: boolean) => {
    if (checked) {
      dispatch(setBookmakers({ bookmakers: [...bookmakers, value] }));
    } else {
      dispatch(setBookmakers({ bookmakers: bookmakers.filter((bookmaker: string) => bookmaker !== value) }));
    }
  };

  const handlePicksRefresh = async () => {
    await getPicks({ sport: sportId, bookmakers, noOfPicks });
    setShowRefreshButton(false);
  };

  const optionRender = ({ label, value }: { label?: any; value: any }) => (
    <Flex align="center" gap={8} className="our-picks__bookmaker-option">
      <div>
        <img src={`../../../logos/bookmakers/${BOOKMAKER_LOGOS[value as string]}`} alt={value as string} />
      </div>
      <div>{label}</div>
    </Flex>
  );

  const tagRender = ({ label, value, count }: { label?: any; value: any; count: number }) => (
    <Tag.CheckableTag
      style={{ margin: 0, padding: 0 }}
      key={value}
      checked={bookmakers.includes(value)}
      onChange={checked => handleTagChange(value, checked)}
    >
      <Flex align="center" gap={8} className="our-picks__bookmaker-tag">
        <div>
          <img src={`../../../logos/bookmakers/${BOOKMAKER_LOGOS[value as string]}`} alt={value as string} />
        </div>
        <div>
          {label} ({count})
        </div>
      </Flex>
    </Tag.CheckableTag>
  );

  return (
    <div className={ourPicksWrapper} style={{ paddingBottom: expandPicks ? 0 : 16 }}>
      <Flex align="center" gap={8} style={{ marginBottom: expandPicks ? 8 : 0 }}>
        {showRefreshButton && !isTabletOrMobile && (
          <div style={{ padding: 8 }}>
            <Button icon={<ReloadOutlined />} type="primary" onClick={handlePicksRefresh}>
              Refresh for new picks
            </Button>
          </div>
        )}
        {!isTabletOrMobile && (
          <Button
            type="primary"
            shape="round"
            onClick={() => dispatch(setExpandPicks({ expandPicks: !expandPicks }))}
            icon={expandPicks ? <UpOutlined /> : <DownOutlined />}
            iconPosition="end"
          >
            {expandPicks ? 'Hide' : 'Show'} Picks
          </Button>
        )}

        {expandPicks ? (
          <>
            <Select
              placeholder="Top Picks"
              defaultValue={20}
              value={noOfPicks}
              onChange={setNoOfPicks}
              variant="filled"
              style={{ width: 150 }}
              options={[
                { value: 20, label: 'Top 20' },
                { value: 30, label: 'Top 30' },
                { value: 40, label: 'Top 40' },
                { value: 50, label: 'Top 50' },
              ]}
            />
            {!isTabletOrMobile && (
              <Button
                onClick={handlePicksRefresh}
                icon={<ReloadOutlined />}
                type="primary"
                shape="circle"
                loading={isFetchingPicks}
              />
            )}
            {isTabletOrMobile ? (
              <Select
                showSearch={!isTabletOrMobile}
                mode="multiple"
                allowClear
                style={{ width: isTabletOrMobile ? '100%' : 200 }}
                placeholder="Filter by Book (Underdog, Fanduel, etc.)"
                defaultValue={bookmakers}
                onChange={handleChange}
                variant="filled"
                // @ts-ignore
                optionRender={optionRender}
                options={pickBookmakers.map(bookmaker => ({
                  value: bookmaker.bookmaker,
                  label: `${BOOKMAKER_NAMES[bookmaker.bookmaker]} (${bookmaker.totalCount})`,
                }))}
              />
            ) : (
              <Flex align="center" style={{ flexWrap: 'wrap', gap: 8 }}>
                {pickBookmakers.map(bookmaker => (
                  <div key={`our-picks-bookmaker-${bookmaker.bookmaker}-tags`}>
                    {tagRender({
                      value: bookmaker.bookmaker,
                      label: BOOKMAKER_NAMES[bookmaker.bookmaker],
                      count: bookmaker.totalCount,
                    })}
                  </div>
                ))}
              </Flex>
            )}
          </>
        ) : null}
      </Flex>
      {expandPicks && !picksLoading && topPicks.length === 0 && filteredPicks.length === 0 && (
        <div style={{ padding: '8px 0' }}>
          There are no picks available. We are hard at work trying to find picks worth betting on.
        </div>
      )}
      {showRefreshButton && isTabletOrMobile && (
        <div style={{ padding: 8 }}>
          <Button icon={<ReloadOutlined />} block type="primary" loading={isFetchingPicks} onClick={handlePicksRefresh}>
            Refresh Picks
          </Button>
        </div>
      )}
      {expandPicks && (topPicks.length || filteredPicks.length) ? (
        <Flex vertical={isTabletOrMobile} gap={16} className="our-picks__list">
          {topPicks.length > 0 ? (
            <Flex vertical={isTabletOrMobile} gap={16} align="center" className={topPicksCss}>
              <div className="our-picks__top-picks-header" />
              <div className="our-picks__top-picks-bottom" />
              <Flex vertical={isTabletOrMobile} gap={8} style={{ width: '100%' }}>
                {orderby(topPicks, ['rating'], ['desc']).map((pick: GroupedPick, index: number) => (
                  <Pick key={`pick-${pick.eventId}-${pick.playerId}-${index}`} pick={pick} condensed={condensed} />
                ))}
              </Flex>
            </Flex>
          ) : null}

          {filteredPicks.length > 0 ? (
            <Flex vertical={isTabletOrMobile} gap={8} className={otherPicksCss}>
              {orderby(filteredPicks, ['rating'], ['desc']).map((pick: GroupedPick, index: number) => (
                <Pick key={`pick-${pick.eventId}-${pick.playerId}-${index}`} pick={pick} condensed={condensed} />
              ))}
            </Flex>
          ) : null}
        </Flex>
      ) : null}
    </div>
  );
}

export default OurPicks;
