import { MlbPlayer, NflPlayer, NbaPlayer, NhlPlayer } from '../../../types';
import { Sport } from '../../../../server/types';

const nameMapping = (playerName: string, team: string | null = null): string => {
  const mapping: Record<string, string> = {
    'J. D. Martinez': 'J.D. Martinez',
    'Jonny Deluca': 'Jonny DeLuca',
    'AJ Brown': 'A.J. Brown',
    'Deebo Samuel': 'Deebo Samuel Sr.',
    'Darius Slay': 'Darius Slay Jr.',
    'Scott Miller': 'Scotty Miller',
    'Brian Thomas Jr': 'Brian Thomas Jr.',
    'Trent Sherfield': 'Trent Sherfield Sr.',
    'Laviska Shenault Jr': 'Laviska Shenault Jr.',
    'Luis Ortiz': team === 'PIT' ? 'Luis L. Ortiz' : 'Luis F. Ortiz',
    'Kevin Byard': 'Kevin Byard III',
    'Chigoziem Okonkwo': 'Chig Okonkwo',
    'JT Ginn': 'J.T. Ginn',
    'Justin Madubuike': 'Nnamdi Madubuike',
    'Ernest Jones': 'Ernest Jones IV',
    // 'Randy Vasquez': '',
    // 'Emerson Hancock': '',
    'Erick All': 'Erick All Jr.',
    // 'Cole Caufield': '',
    // 'Juraj Slafkovsky': '',
    // 'Mike Matheson': '',
    'Jabari Smith Jr': 'Jabari Smith',
    'P.J. Washington': 'PJ Washington',
    'R.J. Barrett': 'RJ Barrett',
    'Michael Porter Jr': 'Michael Porter Jr.',
    'Kelly Oubre Jr': 'Kelly Oubre Jr.',
    'Derrick Jones': 'Derrick Jones Jr.',
    'Kevin Porter Jr.': 'Kevin Porter',
    'Christopher Tanev': 'Chris Tanev',
    'John Beecher': 'Johnny Beecher',
    'Zach Aston-Reese': 'Zachary Aston-Reese',
    'Alexander Wennberg': 'Alex Wennberg',
    'Josh Mahura': 'Joshua Mahura',
    'Alex Kerfoot': 'Alexander Kerfoot',
    'BJ Hill': 'B.J. Hill',
    'Isaiah Stewart II': 'Isaiah Stewart',
    'Tim Hardaway Jr': 'Tim Hardaway Jr.',
    'Cam Thomas': 'Cameron Thomas',
    'Jaren Jackson Jr': 'Jaren Jackson Jr.',
    'Scotty Pippen Jr': 'Scotty Pippen Jr.',
    'Josh Allen': 'Josh Hines-Allen',
    'Andru Phillips': 'Dru Phillips',
    'Jartavius Martin': 'Quan Martin',
    'Patrick Taylor': 'Patrick Taylor Jr.',
    'Nolan Smith': 'Nolan Smith Jr.',
    'Allen Robinson II': 'Allen Robinson',
    'Jake Hummel': 'Jacob Hummel',
    'Mathew Dumba': 'Matt Dumba',
    'Patrick Maroon': 'Pat Maroon',
    'B.J. Boston Jr': 'Brandon Boston Jr.',
  };
  if (mapping[playerName]) return mapping[playerName];

  return playerName;
};

export const normalizeName = (name: string): string => {
  return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const findPlayerByName = (
  playerName: string,
  players: MlbPlayer[] | NflPlayer[] | NbaPlayer[] | NhlPlayer[] | undefined,
  homeTeam?: string,
  awayTeam?: string
): MlbPlayer | NflPlayer | NbaPlayer | NhlPlayer | null => {
  const player = players?.find(p => {
    if (homeTeam && awayTeam)
      return (
        (normalizeName(p.longName).toLowerCase() === normalizeName(playerName).toLowerCase() ||
          normalizeName(p.longName).toLowerCase() === normalizeName(nameMapping(playerName, p.team)).toLowerCase()) &&
        (p.team === homeTeam || p.team === awayTeam)
      );

    return (
      normalizeName(p.longName).toLowerCase() === normalizeName(playerName).toLowerCase() ||
      normalizeName(p.longName).toLowerCase() === normalizeName(nameMapping(playerName, p.team)).toLowerCase()
    );
  });
  if (!player) {
    console.log(`[NOTFOUND]${playerName}`);
    return null;
  }

  return player;
};
