import React from 'react';
import cn from 'classnames';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { MlbTeam, NflTeam, NbaTeam, NflTeamWeather } from '../../types';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

import './MarketTeam.scss';

type Team = Partial<MlbTeam> | NflTeam | NbaTeam;
function MarketTeams({
  homeTeam,
  awayTeam,
  startTime,
  weather,
}: {
  homeTeam: Team | null;
  awayTeam: Team | null;
  startTime: string;
  weather?: NflTeamWeather | undefined;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const startTimeCss = cn('market-team__start-time', {
    'market-team__start-time--mobile': isTabletOrMobile,
  });

  return (
    <div className="market-teams__wrapper">
      {isTabletOrMobile && (
        <Flex align="center" justify={weather ? 'space-between' : 'center'} className={startTimeCss}>
          {weather ? (
            <Flex align="center" gap={4}>
              <Flex align="center" justify="center">
                <img src={weather.icon} alt={weather.label} />
              </Flex>
              <div>{weather.label}</div>
            </Flex>
          ) : (
            <div />
          )}
          <div>{dayjs(startTime).format(isTabletOrMobile ? 'MMMM D, h:mm A' : 'dddd, MMMM D, YYYY h:mm A')}</div>
        </Flex>
      )}
      <div className="market-team__container">
        <div>
          <Flex align="center">
            <div className="market-team__logo home-team__logo">
              <img src={homeTeam?.espnLogo1} alt={homeTeam?.teamName} />
            </div>
            <div className="market-team__name market-team__name--home">
              <div>{homeTeam?.teamName}</div>
              <div className="market-team__city">{homeTeam?.teamCity}</div>
              <div className="market-team__wins">
                {homeTeam?.wins} - {homeTeam?.loss}
              </div>
            </div>
          </Flex>
        </div>
        {!isTabletOrMobile && (
          <Flex align="center" className={startTimeCss} gap={16}>
            {weather ? (
              <Flex align="center" gap={8}>
                <Flex align="center" justify="center">
                  <img src={weather.icon} alt={weather.label} />
                </Flex>
                <div>{weather.label}</div>
              </Flex>
            ) : (
              <div />
            )}
            <div>{dayjs(startTime).format('dddd, MMMM D, YYYY h:mm A')}</div>
          </Flex>
        )}
        <div className="market-team__away">
          <Flex align="center" justify="flex-end">
            <div className="market-team__name">
              <div>{awayTeam?.teamName}</div>
              <div className="market-team__city">{awayTeam?.teamCity}</div>
              <div className="market-team__wins">
                {awayTeam?.wins} - {awayTeam?.loss}
              </div>
            </div>
            <div className="market-team__logo market-team__logo--away">
              <img src={awayTeam?.espnLogo1} alt={awayTeam?.teamName} />
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default MarketTeams;
