import { NhlTeam, NbaTeam, NflTeam, MlbTeam } from '../../../types';
import { normalizeName } from './normalizeText';

export const getTeam = (teams: any[] | undefined, teamName: string): NhlTeam | NbaTeam | NflTeam | MlbTeam | null => {
  return (
    (teams || []).find((team: NhlTeam | NbaTeam | NflTeam | MlbTeam) => {
      return (
        normalizeName(`${team.teamCity} ${team.teamName}`.toLowerCase().replace('.', '')) ===
        normalizeName(teamName.toLocaleLowerCase().replace('.', ''))
      );
    }) || null
  );
};
