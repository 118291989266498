import React from 'react';

import './Header.scss';
import { Button, Flex, Dropdown, Divider, theme } from 'antd';
import type { MenuProps } from 'antd';
import {
  UserOutlined,
  MenuOutlined,
  SyncOutlined,
  LockFilled,
  AreaChartOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import cn from 'classnames';
import { SignedIn, SignedOut, SignOutButton } from '@clerk/clerk-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetBody } from 'src/redux/slices/researchSlice';
import SportSelection from '../SportSelection/SportSelection';
import { useGetUserInfo } from 'src/hooks/useGetUser';

const { useToken } = theme;

const Header = () => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading: userIsLoading } = useGetUserInfo();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
    padding: 0,
    margin: 0,
  };

  const headerCss = cn('app-header', {
    'app-header--mobile': isTabletOrMobile,
  });

  const logoCss = cn('app-header__logo', {
    'app-header__logo--mobile': isTabletOrMobile,
  });

  const handleProfileMenuClick: MenuProps['onClick'] = e => {
    // console.log('click', e);
    if (e.key === 'pricing') {
      navigate('/pricing');
    }
    if (e.key === 'my-account') {
      navigate('/my-account');
    }
  };

  const profileItems: MenuProps['items'] = [
    {
      label: <div className="header__menu-item">My account</div>,
      key: 'my-account',
      icon: <UserOutlined />,
    },
    {
      label: <div className="header__menu-item">Pricing</div>,
      key: 'pricing',
      icon: <SyncOutlined />,
    },
    {
      label: (
        <div className="header__menu-item">
          <SignOutButton>
            <Button type="primary" size="middle" icon={<LockFilled />}>
              Sign Out
            </Button>
          </SignOutButton>
        </div>
      ),
      key: 'sign-out',
    },
  ];

  const profileMenuProps = {
    items: profileItems,
    onClick: handleProfileMenuClick,
  };

  const handleMenuClick: MenuProps['onClick'] = e => {
    navigate(e.key);
  };

  const items: MenuProps['items'] = [
    ...(user?.proPlan
      ? [
          {
            label: <div className="header__menu-item">Betting</div>,
            key: '/sports/nfl/betting',
            icon: <DollarOutlined />,
          },
        ]
      : []),
    {
      label: <div className="header__menu-item">Research</div>,
      key: '/sports/nfl/research',
      icon: <AreaChartOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className={headerCss}>
      {isTabletOrMobile && (
        <SignedIn>
          <Flex align="center" className="app-header__mobile-menu">
            <Dropdown className="app-header__user__dropdown" trigger={['click']} menu={menuProps}>
              <Button icon={<MenuOutlined />} shape={'circle'} size={'middle'} type="primary" />
            </Dropdown>
          </Flex>
        </SignedIn>
      )}
      <Flex align="center" style={{ background: 'transparent' }}>
        <div
          className={logoCss}
          onClick={() => {
            dispatch(resetBody());
            navigate(`/sports/nfl/${user?.proPlan ? 'betting' : 'research'}`);
          }}
        >
          <img src="/logos/rop.png" alt="logo" />
        </div>
        <SignedIn>{!isTabletOrMobile && <SportSelection />}</SignedIn>
      </Flex>
      <div className="app-header__user">
        {!location.pathname.includes('signin') && (
          <SignedOut>
            <Button type="primary" onClick={() => navigate('/signin')} icon={<UserOutlined />}>
              Sign In
            </Button>
          </SignedOut>
        )}
        <SignedIn>
          <Flex align="center" gap={8}>
            {!userIsLoading && !user.basicPlan && !user.proPlan && (
              <Button
                size="small"
                style={{ backgroundColor: 'green' }}
                shape="round"
                onClick={() => navigate('/pricing')}
              >
                try basic
              </Button>
            )}
            {!userIsLoading && user.basicPlan && (
              <Button
                size="small"
                style={{ backgroundColor: 'green' }}
                shape="round"
                onClick={() => navigate('/pricing')}
              >
                try pro
              </Button>
            )}
            <Dropdown
              className="app-header__user__dropdown"
              trigger={isTabletOrMobile ? ['click'] : ['hover']}
              menu={profileMenuProps}
              dropdownRender={menus => (
                <div style={{ ...contentStyle, width: 250 }}>
                  <div className="app-header__user-email">{user.emailAddress}</div>
                  <Divider style={{ margin: 0 }} />
                  {React.cloneElement(menus as React.ReactElement, { style: menuStyle })}
                </div>
              )}
            >
              <Button
                icon={<UserOutlined />}
                shape={isTabletOrMobile ? 'circle' : 'default'}
                size={'middle'}
                type="primary"
              >
                {isTabletOrMobile ? '' : 'My Account'}
              </Button>
            </Dropdown>
          </Flex>
        </SignedIn>
      </div>
    </div>
  );
};

export default Header;
