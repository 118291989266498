import { NbaStatsBody } from './prop-request-body';

export enum MlbStatsMetric {
  PITCHING_BB = 'Pitching>BB',
  PITCHING_BALK = 'Pitching>Balk',
  PITCHING_WILD_PITCH = 'Pitching>Wild Pitch',
  PITCHING_DECISION = 'Pitching>decision',
  PITCHING_FLYOUTS = 'Pitching>flyouts',
  PITCHING_INHERITED_RUNNERS = 'Pitching>Inherited Runners',
  PITCHING_H = 'Pitching>H',
  PITCHING_HR = 'Pitching>HR',
  PITCHING_ER = 'Pitching>ER',
  PITCHING_STRIKES = 'Pitching>Strikes',
  PITCHING_INHERITED_RUNNERS_SCORED = 'Pitching>Inherited Runners Scored',
  PITCHING_GROUNDOUTS = 'Pitching>Groundouts',
  PITCHING_R = 'Pitching>R',
  PITCHING_PITCHING_ORDER = 'Pitching>pitchingOrder',
  PITCHING_ERA = 'Pitching>ERA',
  PITCHING_INNINGS_PITCHED = 'Pitching>Innings Pitched',
  PITCHING_BATTERS_FACED = 'Pitching>Batters Faced',
  PITCHING_SO = 'Pitching>SO',
  PITCHING_PITCHES = 'Pitching>Pitches',
  PITCHING_OUTS = 'Pitching>PitchingOuts',

  FIELDING_PASSED_BALL = 'Fielding>Passed Ball',
  FIELDING_OUTFIELD_ASSISTS = 'Fielding>Outfield assists',
  FIELDING_E = 'Fielding>E',
  FIELDING_PICKOFFS = 'Fielding>Pickoffs',

  BASE_RUNNING_SB = 'Batting>SB',
  BASE_RUNNING_CS = 'Batting>CS',
  BASE_RUNNING_PO = 'Batting>PO',

  HITTING_BB = 'Hitting>BB',
  HITTING_AB = 'Hitting>AB',
  HITTING_SO = 'Hitting>SO',
  HITTING_HR = 'Hitting>HR',
  HITTING_H = 'Hitting>H',
  HITTING_3B = 'Hitting>3B',
  HITTING_2B = 'Hitting>2B',
  HITTING_IBB = 'Hitting>IBB',
  HITTING_RBI = 'Hitting>RBI',
  HITTING_R = 'Hitting>R',
  HITTING_GIDP = 'Hitting>GIDP',
  HITTING_SAC = 'Hitting>SAC',
  HITTING_AVG = 'Hitting>AVG',
  HITTING_BATTING_ORDER = 'Hitting>battingOrder',
  HITTING_SUBSTITUTION_ORDER = 'Hitting>substitutionOrder',
  HITTING_TB = 'Hitting>TB',
  HITTING_SF = 'Hitting>SF',
  HITTING_HBP = 'Hitting>HBP',
}

export type MlbPlayerStats = {
  Pitching: {
    BB: string; //'1';
    Balk: string; //'0';
    ['Wild Pitch']: string; //'0';
    decision: string; //'';
    Flyouts: string; //'2';
    ['Inherited Runners']: string; //'1';
    H: string; //'0';
    HR: string; //'0';
    ER: string; //'0';
    Strikes: string; //'16';
    ['Inherited Runners Scored']: string; //'0';
    Groundouts: string; //'0';
    R: string; //'0';
    pitchingOrder: string; //'4';
    ERA: string; //'1.95';
    InningsPitched: string; //'1.2';
    ['Batters Faced']: string; //'6';
    SO: string; //'1';
    Pitches: string; //'30';
    PitchingOuts: number;
  };
  Fielding: {
    ['Passed Ball']: string; //'0';
    ['Outfield assists']: string; //'0';
    E: string; //'0';
    Pickoffs: string; //'0';
  };
  Hitting: {
    BB: string; //'1'; walk
    AB: string; //'4'; at bats
    battingOrder: string; //'2';
    H: string; //'1';
    IBB: string; //'0'; intentiional walk
    substitutionOrder: string; //'0';
    HR: string; //'0';
    TB: string; //'1'; Total bases
    ['3B']: string; //'0';
    GIDP: string; //'0'; ground into double play
    ['2B']: string; //'0';
    R: string; //'0';
    SF: string; //'0'; sac fly
    SAC: string; //'0'; sac bunts
    HBP: string; //'0' hit by pitch
    RBI: string; //'0';
    SO: string; //'1';
    AVG: string; //'.250';
  };
  BaseRunning: {
    CS: string; //'0';
    SB: string; //'0';
    PO: string; //'0';
  };
  mlbID: string; //'554430';
  playerID: string; //'554430';
  team: string; //'PHI';
  allPositionsPlayed: string; //'P';
  started: string; //'False';
  gameID: string; //'20231024_ARI@PHI';
};

export type MlbGameStat = Record<string, MlbPlayerStats>;

export type MlbStatTypes = 'away' | 'home' | 'all' | 'vs';

export type MlbGameStats = {
  statusCode: number;
  body: MlbGameStat;
};

export type BatterVsPitcher = {
  '2B': string; // '0';
  BB: string; // '0';
  AB: string; // '5';
  AVG: string; // '.200';
  OPS: string; // '.400';
  H: string; // '1';
  RBI: string; // '0';
  HR: string; // '0';
  K: string; // '1';
  OBP: string; // '.200';
  '3B': string; // '0';
  SLG: string; // '.200';
};

export type MlbStatType = Record<
  MlbStatTypes,
  {
    percentage: number;
    stats: {
      game: string;
      gameDate: string;
      total: number;
      thresholdMet: boolean;
      metaData: MlbPlayerStats;
    }[];
  }
>;

export type MlbPlayerStatResult = {
  stats: MlbStatType;
  criteria: NbaStatsBody;
  rating: number | null;
  streak: number;
  hitDifferential: number;
  missDifferential: number;
  streakDifferential: number;
};
