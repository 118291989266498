import React from 'react';
import { Flex, Button, Tag } from 'antd';
import { ArrowRightOutlined, StarFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { NbaTeam, OddsEvent } from 'src/types';
import { useGetNbaTeamsQuery } from 'src/redux/queries/nbaApi';
import { PicksRecord } from '../OurPicks/OurPicks';
import { getTeam } from '../utils/getTeam';

import './BettingEvent.scss';

// const getTeam = (teams: NbaTeam[] | undefined, teamName: string): NbaTeam | null => {
//   return (
//     (teams || []).find((team: NbaTeam) => {
//       return (
//         `${team.teamCity} ${team.teamName}`.toLocaleLowerCase().replace('.', '') ===
//         teamName.toLocaleLowerCase().replace('.', '')
//       );
//     }) || null
//   );
// };
function NFLEvent({
  event,
  handleNavigate,
  picks,
}: {
  event: OddsEvent;
  handleNavigate: (eventId: string) => void;
  picks: PicksRecord[];
}) {
  const { data: teams } = useGetNbaTeamsQuery();

  const homeTeam = getTeam(teams, event.home_team);
  const awayTeam = getTeam(teams, event.away_team);

  const Team2 = ({ team, isHome }: { team: NbaTeam; isHome: boolean }) => {
    const logoCss = cn('betting__event-logo-avatar', {
      'betting__event-logo-avatar--home': isHome,
    });

    return (
      <div>
        <Flex align="flex-start">
          <div className="betting__event-logo">
            <div className={logoCss}>
              <img src={team?.nbaComLogo1} alt={team?.teamAbv} />
            </div>
            <div className="betting__event-team-wrapper">
              <div className="betting__event-team-name">
                {team?.teamName} <span className="city">{team?.teamCity}</span>
              </div>
              <div className="betting__event-win-loss">
                {team?.wins} - {team?.loss}
              </div>
            </div>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <div className="betting__event-wrapper">
      {picks.length > 0 && (
        <div className="betting__event-picks">
          <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}></Tag>
        </div>
      )}
      <div className="betting__event betting__event--secondary">
        <Flex align="center" justify="space-between">
          <Flex align="flex-start" vertical={true} justify="space-between">
            <Team2 team={homeTeam as NbaTeam} isHome />
            <Team2 team={awayTeam as NbaTeam} isHome={false} />
          </Flex>
          <Flex gap={8} vertical={true} align="center" justify="space-between">
            <div className="betting__event-start-time">{dayjs(event.commence_time).format('h:mm A')}</div>
            <Flex gap={8} align="center">
              <Button
                onClick={() => handleNavigate(event.id)}
                icon={<ArrowRightOutlined />}
                shape="round"
                type="primary"
                iconPosition="end"
              >
                Props
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default NFLEvent;
