import React from 'react';
import { Tag } from 'antd';
import { StarFilled, DislikeFilled, CaretUpFilled } from '@ant-design/icons';
const isDevelopment = process.env.NODE_ENV === 'development';

export function Rating({ rating }: { rating: number }) {
  if (isDevelopment) {
    // return <>{rating && rating.toFixed(1)}</>;
  }

  /*
  
  A+ > 100
  A > 90
  B > 80
  C > 70
  D > 60
  F < 60
  */

  const color = rating > 80 ? '#50853a' : rating > 60 ? '#0f50d4' : '#cd201f';
  const icon = rating > 80 ? <StarFilled /> : rating > 60 ? <CaretUpFilled /> : <DislikeFilled />;
  const label = rating > 100 ? 'A+' : rating > 90 ? 'A' : rating > 80 ? 'B' : rating > 60 ? 'C' : 'F';

  return (
    <Tag style={{ margin: 0 }} color={color} icon={icon}>
      {label}
    </Tag>
  );

  if (rating > 80) {
    return (
      <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}>
        Great value
      </Tag>
    );
  }

  if (rating >= 55) {
    return (
      <Tag style={{ margin: 0 }} color={'#0f50d4'} icon={<CaretUpFilled />}>
        Fair value
      </Tag>
    );
  }

  if (rating < 55) {
    return (
      <Tag style={{ margin: 0 }} color={'#cd201f'} icon={<DislikeFilled />}>
        Stay away
      </Tag>
    );
  }

  return null;
}
