import { NbaStatsBody } from '../types';

export enum NhlStatsMetric {
  POINTS = 'points',
  POWERPLAY_POINTS = 'powerPlayPoints',

  GOALS = 'goals',
  SHOOTOUT_GOALS = 'shootoutGoals',
  POWERPLAY_GOALS = 'powerPlayGoals',

  ASSISTS = 'assists',
  POWERPLAY_ASSISTS = 'powerPlayAssists',

  SHOTS = 'shots',
  BLOCKED_SHOTS = 'blockedShots',

  GIVEAWAYS = 'giveaways',
  PLUS_MINUS = 'plusMinus',
  FACEOFFS_WON = 'faceoffsWon',
  PENALTIES_IN_MINUTES = 'penaltiesInMinutes',
  FACEOFFS = 'faceoffs',
  PENALTIES = 'penalties',
  FACEOFFS_LOST = 'faceoffsLost',
  SHIFTS = 'shifts',
  TAKEAWAYS = 'takeaways',
  HITS = 'hits',
  SHOTS_MISSED_NET = 'shotsMissedNet',
  POWERPLAY_TIME_ON_ICE = 'powerPlayTimeOnIce',
  SHORT_HANDED_TIME_ON_ICE = 'shortHandedTimeOnIce',
  TIME_ON_ICE = 'timeOnIce',
  GOALIE_SAVES = 'saves',
  GOALIE_POWERPLAY_SAVES = 'powerPlaySaves',
  GOALIE_SHORT_HANDED_SAVES = 'shortHandedSaves',
  GOALIE_GOALS_AGAINST = 'goalsAgainst',
  GOALIE_SHOTS_AGAINST = 'shotsAgainst',
}

export type NhlPlayerStats = {
  gameID: string; //"20240305_CHI@ARI"
  team: string; // 'EDM'
  shootoutGoals: string; // '0';
  powerPlayGoals: string; // '0';
  blockedShots: string; // '0';
  giveaways: string; // '0';
  plusMinus: string; // '-2';
  goals: string; // '0';
  faceoffsWon: string; // '3';
  penaltiesInMinutes: string; // '0';
  powerPlayAssists: string; // '2';
  shots: string; //  '1';
  powerPlayPoints: string; // '2';
  assists: string; // '2';
  faceoffs: string; // '10';
  penalties: string; //  '0';
  faceoffsLost: string; // '7';
  shifts: string; // '21';
  takeaways: string; // '0';
  hits: string; // '1';
  shotsMissedNet: string; // '2';
  powerPlayTimeOnIce: string; // '4:49';
  shortHandedTimeOnIce: string; // '0:00';
  timeOnIce: string; // '19:43';

  goalsAgainst: string; // '2';
  shotsAgainst: string; // '21';
  saves: string; // '19';
  powerPlaySaves: string; // '3';
  shortHandedSaves: string; // '0';
};

export type NhlGameStat = Record<string, NhlPlayerStats>;

export type NhlGameStats = {
  statusCode: number;
  body: NhlGameStat;
};

export type NhlStatTypes = 'away' | 'home' | 'all' | 'vs';

export type NhlStatType = Record<
  NhlStatTypes,
  {
    percentage: number;
    stats: {
      game: string;
      gameDate: string;
      total: number;
      thresholdMet: boolean;
      metaData: NhlPlayerStats;
    }[];
  }
>;

export type NhlPlayerStatResult = {
  stats: NhlStatType;
  criteria: NbaStatsBody;
  rating: number | null;
  streak: number;
  hitDifferential: number;
  missDifferential: number;
  streakDifferential: number;
};
