import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation, Navigate } from 'react-router-dom';
import { TabsProps, Tabs } from 'antd';
import { HeartFilled, AreaChartOutlined, BankOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Favorites from '../Favorites/Favorites';
import Research from '../Research/Research';
import Betting from '../Betting/Betting';

import { MOBILE_QUERY } from '../../constants';
import { NbaStatsBody, Sport } from '../../types';
import { RootState } from 'src/redux/store';
import { setActiveTab } from 'src/redux/slices/tabsSlice';
import { useGetUserInfo } from 'src/hooks/useGetUser';

function SportLanding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sportId } = useParams();
  const { user } = useGetUserInfo();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);
  const activeTab = useSelector((state: RootState) => state.tabs.activeTab);

  let items: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'betting',
        label: (
          <>
            <BankOutlined /> Betting
          </>
        ),
        children: <Betting />,
      },
      {
        key: 'research',
        label: (
          <>
            <AreaChartOutlined /> Research
          </>
        ),
        children: <Research />,
      },
      {
        key: `favorites`,
        label: (
          <>
            <HeartFilled /> Favorites
          </>
        ),
        children: <Favorites handleNavigation={(type: string) => dispatch(setActiveTab({ activeTab: 'research' }))} />,
      },
    ],
    [dispatch]
  );

  const filterItems = useCallback(
    (items: TabsProps['items']) => {
      if (user.isSignedIn && !user?.proPlan) {
        return (items || []).filter((item: { key: string }) => item.key !== `/sports/${sportId}/betting`);
      }
      return items || [];
    },
    [user, sportId]
  );

  const goToTab = useCallback(
    (key: string) => {
      dispatch(setActiveTab({ activeTab: key }));

      if (key === 'favorites') return;

      if (key === `research` && requestBody.playerId) {
        navigate(`/sports/${sportId}/research/players/${requestBody.playerId}`);
        return;
      }

      navigate(`/sports/${sportId}/${key}`);
    },
    [dispatch, sportId, requestBody.playerId, navigate]
  );

  useEffect(() => {
    if (!activeTab && user.isSignedIn) {
      const filteredItems = filterItems(items || []);

      if (location.pathname.includes(`/research`)) {
        return goToTab('research');
      }

      goToTab(filteredItems[0].key as string);
    }

    if (activeTab && !location.pathname.includes(`/betting`) && !location.pathname.includes(`/research`)) {
      navigate(`/sports/${sportId}/${activeTab}`);
    }

    // if (activeTab === 'favorites' || !user) return;
    // // reset tabs when url changes
    // if (location.pathname.includes(`/research`) && activeTab !== `/sports/${sportId}/research`) {
    //   dispatch(setActiveTab({ activeTab: `/sports/${sportId}/research` }));
    //   navigate(`/sports/${sportId}/research`);
    //   return;
    // }
    // if (location.pathname.includes(`/betting`) && activeTab !== `/sports/${sportId}/betting`) {
    //   dispatch(setActiveTab({ activeTab: `/sports/${sportId}/betting` }));
    //   navigate(`/sports/${sportId}/betting`);
    //   return;
    // }
    // if (!activeTab && user.isSignedIn) {
    //   const filteredItems = filterItems(items || []);
    //   navigate(filteredItems[0].key as string);
    // }
  }, [location.pathname, activeTab, items, user.isSignedIn, filterItems, goToTab, sportId, navigate]);

  if (!user.isSignedIn) return null;

  if (user.isSignedIn && !user?.basicPlan && !user?.proPlan && sportId !== Sport.nfl) {
    return <Navigate to="/sports/nfl/research" replace={true} />;
  }

  return (
    <div className={'app__tabs'}>
      <Tabs
        size={isTabletOrMobile ? 'middle' : 'large'}
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        items={filterItems(items)}
        onChange={goToTab}
        indicator={{ size: origin => origin - 20, align: 'center' }}
      />
    </div>
  );
}

export default SportLanding;
