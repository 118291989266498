import React, { useEffect, useState } from 'react';
import { socket } from './socket';
import { message } from 'antd';
import AppVersionMismatch from './AppVersionMismatch';

function SocketBanner() {
  const [showMismatch, setShowMismatch] = useState(false);

  useEffect(() => {
    // console.log('socket', socket);
    function onConnect() {
      message.success('Connected');
    }

    function onDisconnect() {
      // message.error('Disconnected. Check your network connection');
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    socket.on('app-version-mismatch', () => {
      setShowMismatch(true);
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  if (showMismatch) {
    return <AppVersionMismatch />;
  }

  return null;
}

export default SocketBanner;
