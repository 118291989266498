import { UserSubscriptions } from '../../../server/types';
import { baseApi } from './baseApi';

export const userApi = baseApi.injectEndpoints({
  endpoints: build => ({
    currentUser: build.query<any, void>({
      query: () => '/user',
      providesTags: ['User'],
    }),
    goToPaymentPage: build.mutation<any, { priceId: string }>({
      query: ({ priceId }) => ({
        url: '/go-to-payment-link',
        method: 'POST',
        body: { priceId },
      }),
    }),
    getUserSubscriptions: build.query<UserSubscriptions[], void>({
      query: () => '/user/subscriptions',
      providesTags: ['Subscriptions'],
    }),
    cancelSubscription: build.mutation<any, { subscriptionId: string }>({
      query: ({ subscriptionId }) => ({
        url: `/user/subscriptions/${subscriptionId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    getStripePlans: build.query<any, void>({
      query: () => '/stripe/plans',
    }),
    unsubscribeUser: build.mutation<any, { emailAddress: string }>({
      query: ({ emailAddress }) => ({
        url: '/user/unsubscribe',
        method: 'POST',
        body: { emailAddress },
      }),
    }),
  }),
});

export const {
  useCurrentUserQuery,
  useGoToPaymentPageMutation,
  useGetUserSubscriptionsQuery,
  useCancelSubscriptionMutation,
  useGetStripePlansQuery,
  useUnsubscribeUserMutation,
} = userApi;
