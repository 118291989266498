import { OddsMarkets } from 'src/types';
import { normalizeName } from './normalizeText';

enum OverUnder {
  OVER = 'Over',
  UNDER = 'Under',
  YES = 'Yes',
}

export type PlayerMarketsMetric = {
  bookmaker: string; // Fanduel
  overUnder: 'Over' | 'Under' | 'Yes';
  threshold: number; // point 0.5
  price: number;
};

export type PlayerMarket = Record<string, PlayerMarketsMetric[]>;

export type PlayerMarkets = Record<string, PlayerMarket>; // 'batter_hits':

export const processSportMarkets = ({ odds }: { odds: OddsMarkets }): PlayerMarkets => {
  const result = (odds.bookmakers || []).reduce((obj: PlayerMarkets, bookmaker) => {
    for (const market of bookmaker.markets) {
      const metric = market.key;
      for (const outcome of market.outcomes) {
        obj[normalizeName(outcome.description)] = {
          ...(obj[normalizeName(outcome.description)] || {}),
          [metric]: [
            ...(obj[normalizeName(outcome.description)]?.[metric] || []),
            {
              bookmaker: bookmaker.key,
              overUnder: outcome.name,
              threshold: outcome.point,
              price: outcome.price,
            },
            // ...(bookmaker.key === 'prizepicks'
            //   ? [
            //       {
            //         bookmaker: bookmaker.key,
            //         overUnder: OverUnder.UNDER,
            //         threshold: outcome.point,
            //         price: outcome.price,
            //       },
            //     ]
            //   : []),
          ],
        };
      }
    }
    return obj;
  }, {});

  return result;
};
