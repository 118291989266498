import React, { useMemo } from 'react';
import { Flex } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import './SportSelection.scss';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { updateSport } from '../../redux/slices/researchSlice';
import { Sport } from '../../types';
import { useGetUserInfo } from '../../hooks/useGetUser';
import { setActiveTab } from 'src/redux/slices/tabsSlice';

const SportSelection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useGetUserInfo();

  const userOnFreePlan = !user?.basicPlan && !user?.proPlan;

  const sportRoot = useMemo(() => {
    const route = location.pathname.includes('/betting') ? 'betting' : 'research';
    return route;
  }, [location.pathname]);

  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const handleSetSport = (sport: Sport) => {
    dispatch(updateSport({ sport }));
    if (userOnFreePlan) {
      dispatch(setActiveTab({ activeTab: 'research' }));
    } else {
      dispatch(setActiveTab({ activeTab: 'betting' }));
    }
  };

  const containerCss = cn('sport-selection__container', {
    'sport-selection__container--mobile': isTabletOrMobile,
  });

  return (
    <div className={containerCss}>
      <Flex align="center" gap={16}>
        <NavLink
          onClick={() => handleSetSport(Sport.nfl)}
          to={`/sports/nfl${isTabletOrMobile ? `/${sportRoot}` : ''}`}
          className={({ isActive, isPending }) =>
            isPending
              ? 'sport-selection__link sport-selection__link--pending'
              : isActive
                ? 'sport-selection__link sport-selection__link--active'
                : 'sport-selection__link'
          }
        >
          <img src={'/logos/nfl-logo.png'} alt={'nfl'} />
        </NavLink>
        {!userOnFreePlan && (
          <>
            <NavLink
              onClick={() => handleSetSport(Sport.nba)}
              to={`/sports/nba${isTabletOrMobile ? `/${sportRoot}` : ''}`}
              className={({ isActive, isPending }) =>
                isPending
                  ? 'sport-selection__link sport-selection__link--pending'
                  : isActive
                    ? 'sport-selection__link sport-selection__link--active'
                    : 'sport-selection__link'
              }
            >
              <img src={'/logos/nba-logo.png'} alt={'nba'} />
            </NavLink>
            <NavLink
              onClick={() => handleSetSport(Sport.mlb)}
              to={`/sports/mlb${isTabletOrMobile ? `/${sportRoot}` : ''}`}
              className={({ isActive, isPending }) =>
                isPending
                  ? 'sport-selection__link sport-selection__link--pending'
                  : isActive
                    ? 'sport-selection__link sport-selection__link--active'
                    : 'sport-selection__link'
              }
            >
              <img src={'/logos/mlb-logo.png'} alt={'mlb'} />
            </NavLink>
            <NavLink
              onClick={() => handleSetSport(Sport.nhl)}
              to={`/sports/nhl${isTabletOrMobile ? `/${sportRoot}` : ''}`}
              className={({ isActive, isPending }) =>
                isPending
                  ? 'sport-selection__link sport-selection__link--pending'
                  : isActive
                    ? 'sport-selection__link sport-selection__link--active'
                    : 'sport-selection__link'
              }
            >
              <img src={'/logos/nhl-logo.png'} alt={'nhl'} />
            </NavLink>
          </>
        )}
      </Flex>
    </div>
  );
};

export default SportSelection;
