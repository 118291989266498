import React from 'react';
import { OddsEvent } from 'src/types';
import MLBEvent from './Events/MLBEvent';
import NFLEvent from './Events/NFLEvent';
import NBAEvent from './Events/NBAEvent';
import NHLEvent from './Events/NHLEvent';
import { useGetEventPicksQuery } from '../../redux/queries/bettingApi';
import { PicksRecord } from './OurPicks/OurPicks';

function BettingEvent({ event, handleNavigate }: { event: OddsEvent; handleNavigate: (eventId: string) => void }) {
  const { data: picks = [] }: { data?: PicksRecord[]; isFetching: boolean } = useGetEventPicksQuery(
    {
      eventId: event.id,
    },
    {
      skip: !event.id,
    }
  );

  return event.sport_title === 'MLB' ? (
    <MLBEvent event={event} handleNavigate={handleNavigate} picks={picks} />
  ) : event.sport_title === 'NFL' ? (
    <NFLEvent event={event} handleNavigate={handleNavigate} picks={picks} />
  ) : event.sport_title === 'NBA' ? (
    <NBAEvent event={event} handleNavigate={handleNavigate} picks={picks} />
  ) : event.sport_title === 'NHL' ? (
    <NHLEvent event={event} handleNavigate={handleNavigate} picks={picks} />
  ) : (
    <>Sport not supported</>
  );
}

export default BettingEvent;
