import React from 'react';
import ReactDOM from 'react-dom/client';

import { SignedIn } from '@clerk/clerk-react';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { AntDProvider } from './providers/AntDProvider';
import { ClerkProviderWrapper } from './providers/ClerkProvider';
import { ReduxStoreProvider } from './providers/ReduxStoreProvider';
import { BrowserRouter } from 'react-router-dom';
import AskAQuestion from './components/AskAQuestion/AskAQuestion';
import SocketBanner from './socket/SocketBanner';

import './index.css';

const isDevelopment = process.env.NODE_ENV === 'development';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReduxStoreProvider>
      <ClerkProviderWrapper>
        <AntDProvider>
          <BrowserRouter>
            <SignedIn>
              <SocketBanner />
            </SignedIn>
            <div id="app">
              <App />
            </div>
            <AskAQuestion />
          </BrowserRouter>
        </AntDProvider>
      </ClerkProviderWrapper>
    </ReduxStoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (isDevelopment) reportWebVitals(console.log);
