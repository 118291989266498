import React from 'react';
import { Modal, Button, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { GroupedPick } from './OurPicks';
import PlayerStats from '../../PlayerTrends/PlayerStats';
import StatsTable from '../../PlayerTrends/StatsTable';
import { NbaStatTypes } from '../../../types';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../../constants';
import cn from 'classnames';

import './PicksModal.scss';

const CHART_OPTIOMS = ['all', 'home', 'away', 'vs'];

export default function PickModal({
  pick,
  isOpen,
  children,
  handleClose,
}: {
  pick: GroupedPick;
  isOpen: boolean;
  children: React.ReactNode;
  handleClose: () => void;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  // const dataKeys: NbaStatTypes[] = pick?.stats ? (Object.keys(pick?.stats.stats) as NbaStatTypes[]) : [];
  const dataKeys: NbaStatTypes[] = pick?.stats
    ? (CHART_OPTIOMS.filter(key => pick?.stats && Object.keys(pick?.stats.stats || []).includes(key)) as NbaStatTypes[])
    : [];

  const chartData = pick?.stats
    ? dataKeys
        .map(key => {
          return {
            chartLabel: key === 'vs' ? `${key} ${pick?.requestBody.vsOpponent}` : key === 'all' ? 'Overall' : `${key}`,
            percentage: pick.stats?.stats[key].percentage || 0,
            xAxisKey: 'game',
            dataKey: 'total',
            referenceLine: pick.stats?.criteria.threshold as number,
            chartData: pick.stats?.stats[key].stats as Record<string, any>[],
          };
        })
        .filter(chart => chart.chartData.length > 0)
    : [];

  const chartsAreaCss = cn('pick-modal__charts-area', {
    'pick-modal__charts-area--mobile': isTabletOrMobile,
  });

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: 'chart',
      label: 'Chart',
      children: (
        <div className={chartsAreaCss}>
          <PlayerStats data={chartData} isLoading={false} />
        </div>
      ),
    },
    {
      key: 'table',
      label: 'Stats',
      children: (
        <div className={chartsAreaCss}>
          <StatsTable sport={pick.sport} data={chartData} isLoading={false} position={pick.player?.body.pos} />
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={isOpen}
      title={'Details'}
      onOk={() => {}}
      style={{ top: isTabletOrMobile ? 0 : 100 }}
      width={isTabletOrMobile ? '100%' : '60%'}
      onCancel={() => handleClose()}
      footer={[
        <Button type="primary" shape="round" key="back" onClick={() => handleClose()}>
          close
        </Button>,
      ]}
    >
      <div>
        <div>{children}</div>
        <Tabs defaultActiveKey="chart" items={items} onChange={onChange} />
      </div>
    </Modal>
  );
}
