import React from 'react';
import { Button, Flex, Skeleton, Tag } from 'antd';
import { UpOutlined, DownOutlined, StarFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import './PlayerHeader.scss';

function PlayerHeader({
  playerName,
  headshot,
  teamName,
  teamCity,
  teamLogo,
  position,
  jerseyNum,
  loading,
  handleExpandSection,
  isExpanded,
  gameDetails = null,
  showTeam = true,
  hasPicks = false,
}: {
  playerName: string;
  headshot: string;
  teamName: string;
  teamCity: string;
  teamLogo: string;
  position: string;
  jerseyNum: string;
  loading: boolean;
  handleExpandSection?: (e: React.MouseEvent) => void;
  isExpanded?: boolean;
  gameDetails?: React.ReactNode | null;
  showTeam?: boolean;
  hasPicks?: boolean;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  if (loading) {
    return (
      <div className="player-header__container">
        <Flex align="center" gap={16}>
          {!isTabletOrMobile && (
            <Flex justify="flex-end">
              <div className="player-header__teamLogo">
                <Skeleton.Avatar active style={{ width: 30, height: 30 }} />
              </div>
            </Flex>
          )}
          <div className="player-header__headshot">
            <Skeleton.Avatar active style={{ width: 50, height: 50 }} />
          </div>
          <div className="player-header__name-team">
            <div className="player-header__name">
              <Skeleton.Input active />
            </div>
          </div>
        </Flex>
      </div>
    );
  }

  return (
    <div className="player-header__container" onClick={handleExpandSection && handleExpandSection}>
      {hasPicks && (
        <div className="player-header__has-picks">
          <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}></Tag>
        </div>
      )}
      <Flex align="center">
        {!isTabletOrMobile && (
          <Flex justify="flex-end">
            <div className="player-header__teamLogo">
              <img src={teamLogo} alt={teamName} />
            </div>
          </Flex>
        )}
        <div className="player-header__headshot">
          <img src={headshot} alt={playerName} />
        </div>
        <div className="player-header__name-team">
          <div className="player-header__name">
            {playerName} #{jerseyNum} ({position})
          </div>
          {showTeam && (
            <div className="player-header__team">
              {teamName} <span className="city">{teamCity}</span>
            </div>
          )}
          {gameDetails && <>{gameDetails}</>}
        </div>

        <Flex gap={8} justify="flex-end" align="center" className="player-header__button">
          {handleExpandSection && (
            <Button
              type="primary"
              shape="circle"
              icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
              onClick={handleExpandSection}
            />
          )}
        </Flex>
      </Flex>
    </div>
  );
}

export default PlayerHeader;
