import React from 'react';
import { Sport } from '../../types';
import { PlayerStatsPropsData } from './PlayerStats';
import { Table, Segmented } from 'antd';

import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

import './StatsTable.scss';
import { tableStatColumns } from './statTableColumns';

type StatsTableProps = {
  sport: Sport;
  data: PlayerStatsPropsData[];
  isLoading: boolean;
  position: string | undefined;
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function flattenObject(obj: any, prefix = '') {
  let flattened = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key], prefix + key + '.'));
      } else {
        // @ts-ignore
        flattened[prefix + key] = obj[key];
      }
    }
  }
  return flattened;
}

const StatsTable = ({ sport, data, isLoading, position }: StatsTableProps) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const tableColumns = tableStatColumns(sport, position, isTabletOrMobile);

  const [chartType, setChartType] = React.useState('');

  if (data.length === 0) return null;

  const tableData = data.reduce((obj: Record<string, any>, data) => {
    obj[data.chartLabel] = data.chartData.map((data: any) => {
      return {
        ...(sport === Sport.nfl || sport === Sport.mlb ? flattenObject(data.metaData) : data.metaData),
        game: data.game,
        gameDate: data.gameDate,
      };
    });
    return obj;
  }, {});

  return (
    <div className="player-stats-table__container">
      <div style={{ marginBottom: 16 }}>
        <Segmented<string>
          options={data.map(data => ({
            value: data.chartLabel,
            label: isTabletOrMobile
              ? capitalizeFirstLetter(data.chartLabel.replace('games', ''))
              : capitalizeFirstLetter(data.chartLabel),
          }))}
          size="large"
          onChange={value => {
            setChartType(value);
          }}
        />
      </div>

      <Table
        className="player-stats-table"
        bordered={true}
        scroll={{ x: 'calc(525px + 50%)', y: 500 }}
        pagination={false}
        sticky={true}
        loading={isLoading}
        size="small"
        columns={tableColumns}
        dataSource={tableData[chartType || data[0].chartLabel]}
      />
    </div>
  );
};

export default StatsTable;
