import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BettingPicksSliceState {
  bookmakers: string[];
  expandPicks: boolean;
  condensedView: boolean;
}

const initialState: BettingPicksSliceState = {
  bookmakers: [],
  expandPicks: true,
  condensedView: false,
};

export const bettingPicksSlice = createSlice({
  name: 'bettingPicks',
  initialState,
  reducers: {
    setBookmakers: (state, { payload }: PayloadAction<{ bookmakers: string[] }>) => {
      state.bookmakers = payload.bookmakers;
    },
    setExpandPicks: (state, { payload }: PayloadAction<{ expandPicks: boolean }>) => {
      state.expandPicks = payload.expandPicks;
    },
    setCondensedView: (state, { payload }: PayloadAction<{ condensedView: boolean }>) => {
      state.condensedView = payload.condensedView;
    },
  },
});

export const { setBookmakers, setExpandPicks, setCondensedView } = bettingPicksSlice.actions;

export default bettingPicksSlice.reducer;
