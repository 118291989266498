import React from 'react';
import { Button, message, Flex } from 'antd';
import { useLocation } from 'react-router-dom';
import { useUnsubscribeUserMutation } from 'src/redux/queries/userApi';
import qs from 'qs';

function Unsubscribe() {
  const { search } = useLocation();
  const [unsubscribeUser, { isLoading: isUnsubscribing }] = useUnsubscribeUserMutation();
  const parsedQuery = qs.parse(search.substring(1, search.length)) as Record<string, string>;
  console.log('search', parsedQuery.emailAddress);
  const [successMessage, setSuccessMessage] = React.useState(false);

  const handleUnsubscribe = async () => {
    try {
      await unsubscribeUser({ emailAddress: parsedQuery.emailAddress as string });
      message.success('You have succesfully unsubscribed from email notifications.');
      setSuccessMessage(true);
    } catch (error) {
      message.error('We ran into a problem unsubscribing. please contact support@prostattrends.com');
      console.log(error);
    }
  };

  if (successMessage) {
    return (
      <Flex align="center" justify="center" vertical={true} style={{ padding: 32 }}>
        You have succesfully unsubscribed from email notifications.
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" gap={16} vertical={true} style={{ padding: 32 }}>
      <div>
        Unsubscribe <strong>{parsedQuery.emailAddress}</strong> from email notifications.
      </div>
      <Button type="primary" onClick={handleUnsubscribe} loading={isUnsubscribing}>
        Unsubscribe
      </Button>
    </Flex>
  );
}

export default Unsubscribe;
