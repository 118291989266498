export const MOBILE_QUERY = '(max-width: 900px)';

export const TABLET_QUERY = '(max-width: 1224px)';

export const MIN_NO_OF_GAMES = 3;

export const MAX_NO_OF_GAMES = 20;

export const BOOKMAKER_LOGOS: Record<string, string> = {
  betonlineag: 'betonline.jpg',
  betmgm: 'betmgm.png',
  betrivers: 'betrivers.jpeg',
  betus: 'betus.png',
  bovada: 'bovada.png',
  draftkings: 'draftkings.jpeg',
  fanduel: 'fanduel.jpeg',
  lowvig: '',
  mybookieag: 'mybookie.jpeg',
  pointsbetus: 'pointsbet.png',
  superbook: 'superbook.png',
  unibet_us: 'unibet.png',
  williamhill_us: 'caesars.jpeg',
  wynnbet: 'wynnbet.png',
  ballybet: 'ballybet.jpeg',
  betparx: 'betparx.png',
  espnbet: 'espnbet.jpg',
  fliff: 'fliff.jpeg',
  hardrockbet: 'hardrock.jpeg',
  sisportsbook: 'sisportsbook.jpg',
  tipico_us: 'tipico.png',
  windcreek: 'windcreek.png',
  prizepicks: 'prizepicks.jpeg',
  underdog: 'underdog.png',
};

export const BOOKMAKER_NAMES: Record<string, string> = {
  betonlineag: 'BetOnlineAG',
  betmgm: 'BetMGM',
  betrivers: 'BetRivers',
  betus: 'BetUS',
  bovada: 'Bovada',
  draftkings: 'DraftKings',
  fanduel: 'FanDuel',
  lowvig: 'LowVig',
  mybookieag: 'MyBookieAG',
  pointsbetus: 'PointsBetUS',
  superbook: 'SuperBook',
  unibet_us: 'Unibet',
  williamhill_us: 'WilliamHill',
  wynnbet: 'Wynnbet',
  ballybet: 'BallyBet',
  betparx: 'BetParx',
  espnbet: 'EspnBet',
  fliff: 'Fliff',
  hardrockbet: 'HardRockBet',
  sisportsbook: 'SISportsbook',
  tipico_us: 'Tipico',
  windcreek: 'WindCreek',
  prizepicks: 'PrizePicks',
  underdog: 'Underdog',
};
