import { useMemo, memo, useState } from 'react';
import { Spin, Flex, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetSportEventOddsQuery } from 'src/redux/queries/bettingApi';
import MLBMarket from './Markets/MLBMarket';
import NFLMarket from './Markets/NFLMarket';
import NBAMarket from './Markets/NBAMarket';
import NHLMarket from './Markets/NHLMarket';
import { processSportMarkets } from './utils/processSportsMarkets';
import ScrollToTop from '../Footer/ScrollToTop';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { useGetEventPicksQuery } from '../../redux/queries/bettingApi';
import { PicksRecord } from './OurPicks/OurPicks';

function BettingEventMarkets({ sportsKey, eventId }: { sportsKey: string; eventId: string | null }) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const { sportId = '' } = useParams();
  const {
    data: odds,
    isFetching: oddsFetching,
    isLoading: oddsLoading,
  } = useGetSportEventOddsQuery({ sportsKey, eventId: eventId as string }, { skip: !eventId || !sportsKey });

  const { data: picks = [] }: { data?: PicksRecord[]; isFetching: boolean } = useGetEventPicksQuery(
    {
      eventId: eventId as string,
    },
    {
      skip: !eventId,
    }
  );

  const playerMarkets = useMemo(() => (odds ? processSportMarkets({ odds }) : {}), [odds]);

  const [playerNameSearch, setPlayerNameSearch] = useState<string>('');

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerNameSearch(event.target.value);
  };

  const filteredPlayerMarkets = useMemo(
    () =>
      Object.keys(playerMarkets)
        .sort()
        .filter(key => {
          if (!playerNameSearch) return true;

          return key.toLowerCase().includes(playerNameSearch.toLowerCase());
        })
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: playerMarkets[key],
          };
        }, {}),
    [playerMarkets, playerNameSearch]
  );

  if (oddsFetching || oddsLoading)
    return (
      <Flex align="center" justify="center" style={{ height: 300 }}>
        <Spin size="default" />
      </Flex>
    );

  if (!eventId) return <>Event not found. Please select an event from the list.</>;

  if (!odds && !oddsLoading && !oddsFetching)
    return <div style={{ padding: 16 }}>There are no {sportId.toUpperCase()} events available</div>;

  return (
    <div style={{ paddingTop: !isTabletOrMobile ? 0 : 8 }}>
      <ScrollToTop />
      <Input
        value={playerNameSearch}
        onChange={handleSearchTermChange}
        placeholder="Player name search"
        allowClear
        suffix={<SearchOutlined />}
        variant="filled"
        style={{ width: isTabletOrMobile ? '100%' : 300 }}
      />
      {Object.keys(filteredPlayerMarkets).length === 0 ? (
        <div>No players match your search.</div>
      ) : (
        <>
          {sportId === 'mlb' ? (
            <MLBMarket
              homeTeam={odds?.home_team as string}
              awayTeam={odds?.away_team as string}
              startTime={odds?.commence_time as string}
              playerMarkets={filteredPlayerMarkets}
              picks={picks}
            />
          ) : sportId === 'nfl' ? (
            <NFLMarket
              homeTeam={odds?.home_team as string}
              awayTeam={odds?.away_team as string}
              startTime={odds?.commence_time as string}
              playerMarkets={filteredPlayerMarkets}
              picks={picks}
            />
          ) : sportId === 'nba' ? (
            <NBAMarket
              homeTeam={odds?.home_team as string}
              awayTeam={odds?.away_team as string}
              startTime={odds?.commence_time as string}
              playerMarkets={filteredPlayerMarkets}
              picks={picks}
            />
          ) : sportId === 'nhl' ? (
            <NHLMarket
              homeTeam={odds?.home_team as string}
              awayTeam={odds?.away_team as string}
              startTime={odds?.commence_time as string}
              playerMarkets={filteredPlayerMarkets}
              picks={picks}
            />
          ) : (
            <div>BettingEventMarkets {eventId}</div>
          )}
          <div style={{ textAlign: 'center', padding: 8 }}>**All ratings are based on a 12 game history</div>
        </>
      )}
    </div>
  );
}

const MemoizedBettingEventMarkets = memo(BettingEventMarkets);
export default MemoizedBettingEventMarkets;
