import React, { useMemo } from 'react';
import { MlbStatsMetric, NbaStatsMetric, NflStatsMetric, NhlStatsMetric, Sport } from 'src/types';
import PlayerStats from './PlayerStats';

import './MarketsTable.scss';

type TableRecordBookMaker = {
  name: string;
  price: number;
};

type TableRowData = {
  bookmakers: TableRecordBookMaker[];
  overUnder: 'Over' | 'Under' | 'Yes';
  threshold: number;
  point?: number; // underdog prop
  sport: Sport;
  playerId: string;
  metrics: MlbStatsMetric[] | NbaStatsMetric[] | NflStatsMetric[] | NhlStatsMetric[];
  opponent: string;
  team: string;
};

type TableRecord = Record<string, TableRowData>;

function MarketsTable({
  sport,
  playerId,
  metrics,
  opponent,
  team,
  data,
  noOfGames,
  isHome = false,
  homeTeam = null,
  eventDateTime = null,
}: {
  sport: Sport;
  playerId: string;
  metrics: MlbStatsMetric[] | NbaStatsMetric[] | NflStatsMetric[] | NhlStatsMetric[];
  opponent: string;
  team: string;
  data: {
    bookmaker: string;
    overUnder: 'Over' | 'Under' | 'Yes';
    threshold: number;
    price: number;
  }[];
  noOfGames: number;
  isHome?: boolean;
  homeTeam?: string | null;
  eventDateTime?: string | null;
}) {
  const tableRecords: TableRecord = useMemo(() => {
    return data.reduce((obj: TableRecord, dataRow) => {
      const defaults = {
        sport,
        playerId,
        metrics,
        opponent,
        team,
      };
      if (obj[`${dataRow.overUnder}_${dataRow.threshold}`]) {
        obj[`${dataRow.overUnder}_${dataRow.threshold}`].bookmakers.push({
          name: dataRow.bookmaker,
          price: dataRow.price,
        });
      } else {
        obj[`${dataRow.overUnder}_${dataRow.threshold}`] = {
          bookmakers: [{ name: dataRow.bookmaker, price: dataRow.price }],
          overUnder: dataRow.overUnder,
          threshold: dataRow.threshold,
          ...defaults,
        };
      }

      return obj;
    }, {});
  }, [data, sport, playerId, metrics, opponent, team]);

  return (
    <>
      {Object.values(tableRecords).map((record: TableRowData) => {
        const body = {
          overUnder:
            record.overUnder.toLowerCase() === 'yes'
              ? 'over'
              : record.overUnder.toLowerCase() === 'no'
                ? 'under'
                : (record.overUnder.toLowerCase() as 'over' | 'under'),
          threshold:
            record.point || record.overUnder.toLowerCase() === 'yes' || record.overUnder.toLowerCase() === 'no'
              ? 0.5
              : record.threshold,
          sport,
          playerId,
          metrics,
          vsOpponent: opponent,
          team,
          noOfGames,
        };

        return (
          <PlayerStats
            body={body}
            bookmakers={record.bookmakers}
            isHome={isHome}
            homeTeam={homeTeam}
            eventDateTime={eventDateTime}
          />
        );
      })}
    </>
  );
}

export default MarketsTable;
