import React from 'react';
import { NbaPlayer, NflPlayer, MlbPlayer, NhlPlayer, Sport } from '../../types';
import { Select } from 'antd';
import { useGetNbaPlayersQuery } from '../../redux/queries/nbaApi';
import { useGetNflPlayersQuery } from '../../redux/queries/nflApi';
import { useGetMlbPlayersQuery } from '../../redux/queries/mlbApi';
import { useGetNhlPlayersQuery } from '../../redux/queries/nhlApi';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

const nflPositionSorting: { key: string; label: string }[] = [
  { key: 'QB', label: 'Quarterback' },
  { key: 'RB', label: 'Running Back' },
  { key: 'WR', label: 'Wide Receiver' },
  { key: 'TE', label: 'Tight-end' },
  { key: 'PK', label: 'Kicker' },
  { key: 'DT', label: 'Defensive Tackle' },
  { key: 'DE', label: 'Defensive End' },
  { key: 'LB', label: 'Linebacker' },
  { key: 'CB', label: 'Cornerback' },
  { key: 'S', label: 'Safety' },
];

const nbaPositionSorting: { key: string; label: string }[] = [
  { key: 'PG', label: 'Point Guard' },
  { key: 'SG', label: 'Shooting Guard' },
  { key: 'SF', label: 'Small Forward' },
  { key: 'PF', label: 'Power Forward' },
  { key: 'C', label: 'Center' },
];

const mlbPositionSorting: { key: string; label: string }[] = [
  { key: 'P', label: 'Pitcher' },
  { key: 'C', label: 'Catcher' },
  { key: '1B', label: 'First Baseman' },
  { key: '2B', label: 'Second Baseman' },
  { key: '3B', label: 'Third Baseman' },
  { key: 'SS', label: 'Short Stop' },
  { key: 'LF', label: 'Left Fielder' },
  { key: 'RF', label: 'Right Fielder' },
  { key: 'CF', label: 'Center Fielder' },
  { key: 'DH', label: 'Designated Hitter' },
];

const nhlPositionSorting: { key: string; label: string }[] = [
  { key: 'C', label: 'Center' },
  { key: 'LW', label: 'Left Wing' },
  { key: 'RW', label: 'Right Wing' },
  { key: 'D', label: 'Defenseman' },
  { key: 'G', label: 'Goalie' },
];

const playersByPosition = (
  team: string | null,
  players: NflPlayer[] | NbaPlayer[] | MlbPlayer[] | NhlPlayer[] | undefined
): {
  [index: string]: NflPlayer[] | NbaPlayer[] | MlbPlayer[] | NhlPlayer[];
} => {
  if (!players) return {};
  return players
    .filter(player => !team || player.team === team)
    .reduce(
      (acc, player: NflPlayer | NbaPlayer | MlbPlayer | NhlPlayer) => {
        if (acc[player.pos]) {
          // @ts-ignore
          acc[player.pos].push(player);
        } else {
          acc[player.pos] = [player];
        }
        return acc;
      },
      {} as {
        [index: string]: NflPlayer[] | NbaPlayer[] | MlbPlayer[] | NhlPlayer[];
      }
    );
};

const formatOptions = (
  sport: Sport,
  team: string | null,
  data: NflPlayer[] | NhlPlayer[] | NbaPlayer[] | MlbPlayer[] | undefined
) => {
  if (!data) return [];

  const groupedPlayers = playersByPosition(team, data);

  const positions =
    sport === Sport.nhl
      ? nhlPositionSorting
      : sport === Sport.mlb
        ? mlbPositionSorting
        : sport === Sport.nfl
          ? nflPositionSorting
          : nbaPositionSorting;

  const result = positions.reduce((arr: any, group): any => {
    if (!groupedPlayers[group.key] || groupedPlayers[group.key].length === 0) return arr;

    arr.push({
      label: <span>{group.label}</span>,
      title: group.key,
      options: (groupedPlayers[group.key] || [])
        .map(player => ({
          team: player.team,
          value: player.playerID,
          label: `${player.longName} ${player?.pos} (${player.team})`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    });
    return arr;
  }, []);
  return result;
};

const PlayerListing = ({
  sport,
  team,
  playerId,
  onChange,
}: {
  sport: Sport;
  team: null | string;
  playerId: string | null;
  onChange: ({ playerId, team }: { playerId: string | null; team: string | null }) => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const { data: nbaPlayers, isLoading: isLoadingNbaPlayers } = useGetNbaPlayersQuery(undefined, {
    skip: sport !== Sport.nba,
  });
  const { data: nflPlayers, isLoading: isLoadingNFLPlayers } = useGetNflPlayersQuery(undefined, {
    skip: sport !== Sport.nfl,
  });
  const { data: mlbPlayers, isLoading: isLoadingMlbPlayers } = useGetMlbPlayersQuery(undefined, {
    skip: sport !== Sport.mlb,
  });
  const { data: nhlPlayers, isLoading: isLoadingNhlPlayers } = useGetNhlPlayersQuery(undefined, {
    skip: sport !== Sport.nhl,
  });

  const onSearch = (value: string) => {
    // console.log('search:', value);
  };

  const filterOption = (input: string, option?: any) => {
    const isGroup = Array.isArray(option.options);
    if (isGroup) {
      return false;
    }
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  const players =
    sport === Sport.nba ? nbaPlayers : sport === Sport.mlb ? mlbPlayers : sport === Sport.nhl ? nhlPlayers : nflPlayers;

  const selectOptions = formatOptions(
    sport,
    team,
    sport === Sport.nba ? nbaPlayers : sport === Sport.mlb ? mlbPlayers : sport === Sport.nhl ? nhlPlayers : nflPlayers
  );

  return (
    <Select
      showSearch
      loading={isLoadingNbaPlayers || isLoadingNFLPlayers || isLoadingMlbPlayers || isLoadingNhlPlayers}
      variant="filled"
      placeholder="All Players"
      optionLabelProp="label"
      optionFilterProp="children"
      onChange={value => {
        if (!value) {
          onChange({ playerId: null, team: null });
          return;
        }

        // console.log('players', players);
        const player = players?.find(player => player.playerID === value);

        // console.log('player', player);

        onChange({
          playerId: value,
          team: player?.team || null,
        });
      }}
      value={playerId}
      // filterOption={true}
      // optionFilterProp="children"
      filterOption={filterOption}
      onSearch={onSearch}
      options={selectOptions}
      size={isTabletOrMobile ? 'large' : 'middle'}
      style={{ width: isTabletOrMobile ? '100%' : 250 }}
    />
  );
};

export default PlayerListing;
