import { baseApi } from './baseApi';
import {
  NbaStatsBody,
  NhlTeams,
  NhlTeam,
  NhlPlayers,
  NhlPlayer,
  NhlPlayerInfo,
  NhlPlayerStatResult,
} from '../../types';

export const nhlApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getNhlTeams: build.query<NhlTeam[], void>({
      query: () => `/nhl/teams`,
      transformResponse: async (baseQueryReturnValue: Promise<NhlTeams>) => {
        const teams = await baseQueryReturnValue;
        return teams.body;
      },
    }),
    getNhlPlayers: build.query<NhlPlayer[], void>({
      query: () => `/nhl/players`,
      transformResponse: async (baseQueryReturnValue: Promise<NhlPlayers>) => {
        const players = await baseQueryReturnValue;
        return players.body.filter(player => !!player.team);
      },
    }),
    getNhlPlayerInfo: build.query<NhlPlayerInfo, { playerId: string }>({
      query: ({ playerId }) => `/nhl/players/${playerId}`,
    }),
    getNhlPlayerStats: build.query<NhlPlayerStatResult, { playerId: string; body: NbaStatsBody }>({
      query: ({ playerId, body }) => ({
        url: `/nhl/players/${playerId}/stats`,
        method: 'POST',
        body: body,
      }),
    }),
    getNhlGameOdds: build.query<any, { gameDate: string }>({
      query: ({ gameDate }) => `/nhl/odds/${gameDate}`,
    }),
  }),
});

export const {
  useGetNhlTeamsQuery,
  useGetNhlPlayersQuery,
  useGetNhlPlayerInfoQuery,
  useGetNhlPlayerStatsQuery,
  useGetNhlGameOddsQuery,
} = nhlApi;
