import React, { useState } from 'react';
import { PlayerMarketsMetric } from '../utils/processSportsMarkets';
import { OddsSportsMarket, Sport, NbaPlayer } from 'src/types';
import MarketHeader from '../MarketHeader';
import MarketsTable from '../MarketsTable';
import { UnmountClosed } from 'react-collapse';

import './MLBPlayerMarket.scss';

function NBAPlayerMarket({
  player,
  bettingMarkets,
  market,
  opponent,
  noOfGames,
  hasPicks = false,
  isHome = false,
}: {
  player: NbaPlayer;
  opponent: string;
  bettingMarkets: PlayerMarketsMetric[];
  market: OddsSportsMarket | undefined;
  noOfGames: number;
  hasPicks?: boolean;
  isHome?: boolean;
}) {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  if (!player) {
    // TODO log missing names
    return null;
  }

  const handleExpandSection = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(prev => !prev);
  };

  return (
    <div className="mlb-player-market__wrapper">
      <MarketHeader
        label={market?.label as string}
        playerId={player?.playerID}
        team={player.team}
        opponent={opponent}
        sport={Sport.nba}
        threshold={bettingMarkets[0].threshold ?? 0}
        metrics={market?.statMetric || []}
        noOfGames={noOfGames}
        isExpanded={isExpanded}
        handleSetExpanded={handleExpandSection}
        hasPicks={hasPicks}
      />
      <UnmountClosed isOpened={isExpanded}>
        <MarketsTable
          metrics={market?.statMetric || []}
          playerId={player?.playerID}
          team={player.team}
          opponent={opponent}
          sport={Sport.nba}
          data={bettingMarkets}
          noOfGames={noOfGames}
          isHome={isHome}
        />
      </UnmountClosed>
    </div>
  );
}

export default NBAPlayerMarket;
