import React from 'react';
import cm from 'classnames';
import { Flex } from 'antd';
import { OddsFreePick } from '../../types';
import { useGetFreePicksQuery } from '../../redux/queries/bettingApi';
import { BOOKMAKER_LOGOS } from '../../constants';
import './MobileFreePicks.scss';

export default function MobileFreePicks() {
  const { data: freePicks } = useGetFreePicksQuery();

  const Pick = ({ pick }: { pick: OddsFreePick }) => {
    const overUnderCss = cm('mobile-free-pick__prop', {
      'mobile-free-pick__prop--over': pick.overUnder === 'over',
      'mobile-free-pick__prop--under': pick.overUnder === 'under',
    });

    return (
      <Flex className="mobile-free-pick" align="center" justify="space-between">
        <Flex>
          <div className="mobile-free-pick__team-logo">
            <img src={pick.teamLogo} alt={pick.teamName} />
          </div>
          <div className="mobile-free-pick__player-headshot">
            <img src={pick.playerHeadshot} alt={pick.playerName} />
          </div>
          <div className="mobile-free-pick__info">
            <div className="mobile-free-pick__player-name">{pick.playerName}</div>
            <div className="mobile-free-pick__team-name">
              {pick.teamCity} <span>{pick.teamName}</span>
            </div>
            <div className="mobile-free-pick__market">{pick.market}</div>
          </div>
        </Flex>
        <Flex>
          <div className="mobile-free-pick__bookmaker">
            <img src={`../../../logos/bookmakers/${BOOKMAKER_LOGOS[pick.bookMaker]}`} alt={pick.bookMaker} />
          </div>
          <div className={overUnderCss}>
            <div className="mobile-free-pick__overUnder">{pick.overUnder}</div>
            <div className="mobile-free-pick__threshold">{pick.threshold}</div>
          </div>
        </Flex>
      </Flex>
    );
  };

  return (
    <div className="mobile-free-picks-wrapper">
      <div className="mobile-free-picks-wrapper__title">Daily Free Picks</div>
      {Object.values(freePicks || {}).map(pick => (
        <Pick key={`${pick.sport}-free-pick`} pick={pick} />
      ))}
    </div>
  );
}
